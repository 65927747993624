import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const MainNavStyles = styled.nav`
  display: none;
  @media ${media.sm} {
    display: block;
    border-bottom: solid 1px #d1d1d1;
    background-color: var(--white);
    padding: 1.5rem 0 0 0;
    margin: var(--auto);
    ul {
      max-width: 700px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      padding-left: 10px;
      li {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 15px;
        @media ${media.md} {
          margin: 0 30px;
        }
        a {
          padding: 0 0.5rem 1.5rem 0.5rem;
          display: block;
          font-weight: 600;
          text-transform: uppercase;
          border-bottom: solid 1px transparent;
          position: relative;
          &:hover,
          &:focus {
            color: var(--gold);
            &::after {
              position: absolute;
              bottom: -2px;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: var(--black);
              content: '';
            }
          }
        }
        a[aria-current='page'] {
          color: var(--gold);
          /* border-bottom: solid 1px var(--black); */
          &::after {
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--black);
            content: '';
          }
        }
      }
    }
    /* .e-p {
      margin-top: 6rem;
    } */
  }
`
const MainNav = ({ alt }) => (
  <MainNavStyles>
    <ul className={alt ? 'e-p' : ''}>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/approach/">Approach</Link>
      </li>
      <li>
        <Link to="/opinion/">Opinion</Link>
      </li>
      <li>
        <Link to="/careers/">Careers</Link>
      </li>
      <li>
        <Link to="/contact-us/">Contact</Link>
      </li>
    </ul>
  </MainNavStyles>
)

export default MainNav
